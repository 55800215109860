const colors = {
  // Theme UI colour names
  text: "#111",
  background: "#ffffff",
  primary: "#00cec8",
  secondary: "#05a",
  //accent: "#07c",
  accent: "#00cec8",
  muted: "#737373",

  // Additional colour names
  bgAccent: "rgba(0,0,0,0.1)",
  bgOpaque: "rgba(255,255,255,0.9)",
}

colors.bgGradient = `linear-gradient(150deg, ${colors.accent} 10%, ${
  colors.text
} 60%, ${colors.text} 100%)`

export default colors
